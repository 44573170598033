// import code for every page

import '@/global';
import '@/components/exitPopup';
import '@/components/sw';
import '@/components/emailGrabber';

// import code by section presence
// section should have data-js-import="name" attribute

const sections = [
  'header-navigation',
  'contact-form',
  'path-to-trading',
  'testimonials',
  'affiliate-dashboard',
  'earnings-calculator',
  'you-need-discipline',
  'mentors-you-trust',
  'one-place',
  'best-time-invest',
  'traders-joined',
  'money-back',
  'faq',
  'sign',
  'pricing-cards',
  'referral-data',
  'trading-room',
  'free-trading-plan-hero',
  'cup-of-coffee',
  'reset-password',
  'sidebar-nav',
  'affiliate-withdrawals',
  'affiliate-payments',
  'marketing-materials',
  'broker',
  'broker-reg',
  'countdown-ribbon',
  'b2b-demo',
  'forex-mentors-hero',
  //'meet-the-mentors',
  'home-pricing',
  'trading-tools',
  'talent-awakened',
  'dunning-kruger-graph',
  'forecast-future',
  'single-perspective',
  'heat-map',
  'what-you-earn',
  'funded-traders',
  'academy-slider',
  'economic-calendar',
  'profit-calculator',
  'lot-size-calculator',
  'blue-cards-slider',
  'var-calculator',
  'tools-slider',
  'sidebar-heatmap',
  'strength-heatmap-widget',
  'strength-meter',
  'swap-calculator',
  'promo-ribbon',
  'compounding-calculator',
  'pricing-table',
  'live-streams',
  'academy-courses',
  'who-are-we',
  'blog-page',
  'monte-carlo-simulation',
  'chart-patterns',
  'newsletter-full-width',
  'cheat-sheets-downloads',
  'nfp-stream',
  'nfp-download',
  'mt4-demo',
  'real-time-coaching',
  'make-sense-markets',
  'brokers-cost-calculation',
  'more-content',
  'chart-series-ribbon',
  'our-initiation-program',
  'asset-price-animation',
  'new-pricing',
  // 'expert-coaches',
  'home-hero-questions',
  'trading-competition-section',
  'trading-competition-countdown',
  'academy-slider',
  'coach-cards',
  'expert-coaches-slider',
  'scrolling-ticker',
  'blog-categories',
  'contest-page',
  'nfp-article',
  'signals-table',
  'master-trading',
  'pip-calculator',
];

sections
  .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
  .forEach((name) => {
    import(
      // /* webpackChunkName: "forexvpsChunk" */
      `@/sections/${name}`
    );
  });
