// import code for every page

import { loadSvg } from '@/core/utils';
import '@/helpers/revealAnimations';
import '@/helpers/smoothScroll';
import '@/helpers/glightbox';
import '@/helpers/freshchat';
import '@/helpers/date';
import '@/helpers/eventLog';
import '@/helpers/wp-reg';
import '@/components/cookieConsent';
import '@/components/webinarCountdown';
import '@/components/webinarGrabEmail';
import '@/components/accessTraderoom';
import '@/components/socialProof';
import { vimeoPlaceholderClickHandler } from '@/core/utils';
import { lazyLoadVideo } from '@/helpers/dom';
import '@/core/traderoom';
import '@/components/heroEmailSignup';
import FormManager from '@/core/FormManager';

// load svg sprite

document.addEventListener('DOMContentLoaded', () => {
  loadSvg(httApp.svgSpriteUrl);
});

export const formManager = new FormManager();

// vimeo placeholders

document.querySelectorAll('.js-vimeo-placeholder').forEach((element) => {
  element.addEventListener('click', vimeoPlaceholderClickHandler);
});

// expand mobile nav accordion

document.querySelectorAll('.js-mobile-menu-expand').forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.toggle('active');
    const items = button.nextElementSibling;

    items.style.height = button.classList.contains('active')
      ? `${items.scrollHeight}px`
      : 0;
  });
});

// load hero video

document.querySelectorAll('.js-hero-video').forEach((video) => {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      lazyLoadVideo(video);
    }, 500);
  });

  // if (window.innerWidth > 991) {
  // }
});
