import { forgotPwOpenTab } from '@/sections/sign';

function showError(form, error) {
  // Search through all error messages and display the correct one
  form.querySelectorAll('.js-form-error').forEach((element) => {
    if (element.dataset.validDisplay === error) {
      element.classList.add('show');
    }
  });
}

function hideAllErrors() {
  document.querySelectorAll('.js-form-error').forEach((element) => {
    element.classList.remove('show');
  });
}

export function validateForm(form) {
  // Reset back to valid and hide any errors before rechecking
  let valid = true;
  hideAllErrors();
  // Loop through form elements and check for validity state
  for (let i = 0; i < form.elements.length - 1; i++) {
    if (form.elements[i].required) {
      if (!form.elements[i].validity.valid) {
        const error = form.elements[i].dataset.validError;
        showError(form, error);
        valid = false;
      }
    }
  }
  return valid ? true : false;
}

export function showSystemError(field, msg) {
  field.innerHTML = msg;
  field.classList.add('error');
  document.querySelectorAll('.js-forgot-pw').forEach((element) => {
    element.addEventListener('click', () => {
      forgotPwOpenTab();
    });
  });
}

// document.querySelectorAll('.js-view-password-toggle').forEach((toggle) => {
//   toggle.addEventListener('click', (e) => {
//     const targetForm = document.querySelector(`.${e.target.dataset.target}`);
//     const field = targetForm.querySelector("input[name='password']");
//     const active = targetForm.querySelector('.js-view-password-toggle.active');
//     const hidden = targetForm.querySelector('.js-view-password-toggle.hidden');
//     if (field.type === 'password') {
//       field.type = 'text';
//       active.style.display = 'block';
//       hidden.style.display = 'none';
//     } else {
//       field.type = 'password';
//       active.style.display = 'none';
//       hidden.style.display = 'block';
//     }
//   });
// });
