import { sendGAevent } from '@/core/analytics';
import {
  getRecaptchaToken,
  isUserLoggedIn,
  processEmailCapture,
  smFunc,
} from '@/core/thinkhuge';
import { modalOff, redirectPage } from '@/helpers/form-dom';
import { randomString } from '@/core/utils';
import { showSystemError, validateForm } from '@/helpers/form-validation';
import { fetchAndRedirectTR } from '@/core/traderoom';
import { loadAppleAuth, loadFacebookAuth } from '@/helpers/socialLogin';
import { formManager } from '@/global';

export default class FormReg {
  constructor(formElement) {
    this.form = formElement;
    this.registerCheckComplete = false;
    this.registeredUserEmail = '';
    this.popup = document.querySelector('.js-popup');
    this.registerTab = this.popup.querySelector('.js-register');
    this.loginTab = this.popup.querySelector('.js-login');
    this.landingSuccessTab = this.popup.querySelector('.js-landing-success');
    this.forgotTab = this.popup.querySelector('.js-forgot');
    this.allTabs = [
      this.registerTab,
      this.loginTab,
      this.landingSuccessTab,
      this.forgotTab,
    ];

    // binding
    this.initEventListeners = this.initEventListeners.bind(this);
    this.toggleSpinner = this.toggleSpinner.bind(this);
    this.socialSuccessCallback = this.socialSuccessCallback.bind(this);
    this.submitRegisterForm = this.submitRegisterForm.bind(this);
    this.landingSuccessOpenTab = this.landingSuccessOpenTab.bind(this);
    this.grabEmail = this.grabEmail.bind(this);
    this.switchActiveTab = this.switchActiveTab.bind(this);
    this.loggedUserRouter = this.loggedUserRouter.bind(this);
    this.handleGoogleRegisterResponse =
      this.handleGoogleRegisterResponse.bind(this);
    this.googleSocialRegister = this.googleSocialRegister.bind(this);
    this.appleSocialRegister = this.appleSocialRegister.bind(this);
    this.facebookSocialRegister = this.facebookSocialRegister.bind(this);
  }

  initEventListeners() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submitRegisterForm();
    });
    this.form.addEventListener(
      'change',
      () => {
        sendGAevent('eReg-click', 'reg-started', window.location.pathname);
      },
      { once: true }
    );

    // loadGoogleAuth(this.handleGoogleRegisterResponse, () => {
    //   const btn = this.form.querySelector('.googleSignInButton');
    //   window.google.accounts.id.renderButton(btn, {
    //     locale: 'en-GB',
    //     // eslint-disable-next-line camelcase
    //     click_listener: this.googleSocialRegister,
    //   });
    // });

    this.form
      .querySelector('.facebookButton')
      .addEventListener('click', (e) => {
        e.preventDefault();
        sendGAevent('ext-cta', 'social-facebook', window.location.pathname);
        this.facebookSocialRegister();
      });

    this.form.querySelector('.appleButton').addEventListener('click', (e) => {
      e.preventDefault();
      sendGAevent('ext-cta', 'social-apple', window.location.pathname);

      formManager.appleCallbackSuccess = (details) =>
        this.socialSuccessCallback(details, 'apple');
      formManager.appleCallbackFailure = () => this.toggleSpinner(false);
      this.appleSocialRegister();
    });

    this.form.querySelectorAll('.js-view-password-toggle').forEach((toggle) => {
      toggle.addEventListener('click', () => {
        const targetForm = this.form;
        const field = targetForm.querySelector("input[name='password']");
        const active = targetForm.querySelector(
          '.js-view-password-toggle.active'
        );
        const hidden = targetForm.querySelector(
          '.js-view-password-toggle.hidden'
        );
        if (field.type === 'password') {
          field.type = 'text';
          active.style.display = 'block';
          hidden.style.display = 'none';
        } else {
          field.type = 'password';
          active.style.display = 'none';
          hidden.style.display = 'block';
        }
      });
    });
  }

  async socialSuccessCallback(result, social) {
    // TODO Needs some additional logic to determine if user was on popup or the hero registration

    const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

    const form = this.form,
      errDiv = form.querySelector('.regFormErrMsg'),
      redirect = form.querySelector("input[name='register_redirect']").value;

    smFunc
      .getJson('/app/auth/process/?do=socialLogin', 'POST', {
        method: social,
        code: result,
        recaptchaToken,
        redirect,
      })
      .then((data) => {
        if (data.result === 'success') {
          return data;
        } else {
          throw 'fail';
        }
      })
      .then((data) => {
        if (data.action === 'LOGIN') {
          redirectPage(redirect, data.tr_access);
        } else if (data.action === 'REGISTER') {
          // if (!popup.classList.contains('on')) {
          //   modalOn(popup);
          // }
          // registerOpenTab();

          const regForm = this.form,
            email = regForm.querySelector("input[name='email']"),
            username = regForm.querySelector("input[name='username']"),
            fullname = regForm.querySelector("input[name='fullname']"),
            password = regForm.querySelector("input[name='password']"),
            avatar = regForm.querySelector("input[name='avatar']"),
            socialEl = regForm.querySelector("input[name='social']"),
            socialButtons = document.querySelectorAll('.social-signin'),
            ios = regForm.querySelector("input[name='ios']");

          regForm.classList.add('is-social');

          // disable email
          email.value = data.data.email;
          email.disabled = true;

          fullname.value = `${data.data.name || ''} ${
            data.data.familyName || ''
          }`.trim();

          // hide password
          password.value = randomString();
          password.parentElement.parentElement.style.display = 'none';

          avatar.value = data.data.avatar;
          ios.value = data.data.iosToken || '';
          socialEl.value = social;

          socialButtons.forEach((item) => (item.style.display = 'none'));
          username.focus();
        }
      })
      .catch((e) => {
        console.error(e);
        // loginOpenTab();
        errDiv.innerHTML =
          'An error occurred. Please try again or choose another method of registration.';
        errDiv.classList.add('error');
      })
      .finally(() => {
        this.toggleSpinner(false);
      });
  }

  async submitRegisterForm() {
    event.preventDefault();
    if (this.registerCheckComplete === true) return;
    this.toggleSpinner();

    const form = this.form,
      username = form.querySelector("input[name='username']").value,
      email = form.querySelector("input[name='email']").value,
      password = form.querySelector("input[name='password']").value,
      fullname = form.querySelector("input[name='fullname']").value,
      avatar = form.querySelector("input[name='avatar']").value,
      social = form.querySelector("input[name='social']").value,
      // phoneCC = form.querySelector("select[name='phoneCc']").value,
      // phone = form.querySelector("input[name='phone']").value,
      tos = form.querySelector("input[name='tos']").checked ? 1 : 0,
      ios = form.querySelector("input[name='ios']").value,
      regflag = document.querySelector("input[name='regflag']")?.value,
      customAP = document.querySelector("input[name='customAP']")?.value,
      skipAP = document.querySelector("input[name='skipAP']")?.value,
      submitBtn = form.querySelector("button[name='submit_btn']"),
      submitButtonTxt = submitBtn.innerHTML,
      regUrl = window.location.pathname,
      errorMsg = form.querySelector('.regFormErrMsg'),
      action = event.target.dataset.action;

    let redirect = form.querySelector("input[name='register_redirect']").value;
    if (event.target.dataset.redirect) {
      redirect = event.target.dataset.redirect;
    }

    errorMsg.innerHTML = '';
    errorMsg.classList.remove('error');

    submitBtn.setAttribute('disabled', 'disabled');
    submitBtn.innerHTML = 'Please wait&hellip;';

    function resetSubmitButton() {
      submitBtn.innerHTML = submitButtonTxt;
      submitBtn.removeAttribute('disabled');
    }

    if (!validateForm(form)) {
      sendGAevent('eReg-error', 'field-error', window.location.pathname);
      resetSubmitButton();
      this.toggleSpinner(false);
      return;
    }

    if (!tos) {
      sendGAevent('eReg-error', 'tos-uncheck', window.location.pathname);
      showSystemError(
        errorMsg,
        'Please accept the terms of service to continue'
      );
      resetSubmitButton();
      this.toggleSpinner(false);
      return;
    }

    const recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey);

    const timezone = new Date().getTimezoneOffset();

    smFunc
      .getJson('/app/auth/process/?do=register', 'POST', {
        username,
        email,
        password,
        fullname,
        avatar,
        social,
        // phoneCC,
        // phone,
        tos,
        ios,
        regflag,
        customAP,
        skipAP,
        timezone,
        recaptchaToken,
        redirect,
        regUrl,
      })
      .then((data) => {
        if (data.result === 'success') {
          sendGAevent('eReg-click', 'reg-completed', window.location.pathname);

          this.registerCheckComplete = true;
          this.registeredUserEmail = email;

          switch (action) {
            case 'affiliate-program':
              modalOff(this.popup, this.registerCheckComplete);
              window.location.reload();
              break;
            case 'courses':
              modalOff(this.popup, this.registerCheckComplete);
              redirectPage(redirect, false);
              break;
            case 'monitor':
              this.landingSuccessOpenTab('monitor', 'registeredmonitor');
              break;
            case 'tradingPlan':
              this.landingSuccessOpenTab(
                'tradingPlan',
                'registered-trading-plan'
              );
              break;
            case 'chartPatternSeries':
              modalOff(this.popup, this.registerCheckComplete);
              redirectPage(false, false);
              break;
            case 'pricing':
              fetchAndRedirectTR('pricing');
              break;
            case 'tradingCompetition':
              fetchAndRedirectTR('tradingCompetition');
              break;
            case 'trackATrader':
              fetchAndRedirectTR('trackATrader');
              break;
            default:
              this.loggedUserRouter('registeredtraderoom');
              break;
            // disabled 26.05.2022
            // default:
            //   loggedUserRouter('registeredwebinar');
            //   break;
          }
        } else if (data.result === 'fail') {
          showSystemError(errorMsg, data.reason);
        }
      })
      .catch((e) => {
        sendGAevent('eReg-error', 'error-processing', window.location.pathname);
        showSystemError(errorMsg, 'Error processing form');
        console.error(e);
      })
      .finally(() => {
        resetSubmitButton();
        this.toggleSpinner(false);
      });
  }

  landingSuccessOpenTab(type, grabSource) {
    this.landingSuccessTab.dataset.type = type;

    // if (landingWebinarCta) {
    //   landingWebinarCta.dataset.type = type;
    // }

    this.grabEmail(grabSource);

    // disabled 26.05.2022
    // popup.classList.remove('hide-right');
    this.popup.classList.add('hide-right');
    this.popup.classList.add('hide-steps');

    if (type === 'tradingPlan') {
      smFunc.getJson('/app/downloads/process/', 'POST', {}).then((data) => {
        if (data.result === 'success') {
          window.location.assign(data.download);
        }
      });
    }

    this.switchActiveTab(this.landingSuccessTab);
  }

  grabEmail(grabSource = '') {
    if (!grabSource) return;

    let email = this.registeredUserEmail;

    // take email from logged user information
    if (!email) {
      if (
        isUserLoggedIn() &&
        'email' in httApp.userInfo &&
        httApp.userInfo.email !== ''
      ) {
        email = httApp.userInfo.email;
      }
    }

    if (!email) return;

    processEmailCapture(email, grabSource);
  }

  switchActiveTab(newTab = false) {
    for (const tab of this.allTabs) {
      if (tab === newTab) continue;

      tab.classList.toggle('active', false);
    }

    if (newTab) newTab.classList.toggle('active', true);
  }

  loggedUserRouter(grabSource = 'loggedinusertraderoom') {
    this.grabEmail(grabSource);
    this.toggleSpinner();
    fetchAndRedirectTR()
      .then()
      .catch(() => {
        this.toggleSpinner(false);
      });
  }

  handleGoogleRegisterResponse(response) {
    this.toggleSpinner();
    this.socialSuccessCallback(response.credential, 'google');
  }

  googleSocialRegister() {
    sendGAevent('ext-cta', 'social-google', window.location.pathname);
  }

  async appleSocialRegister() {
    await loadAppleAuth();

    this.toggleSpinner();
    AppleID.auth.signIn();
  }

  async facebookSocialRegister() {
    await loadFacebookAuth();

    this.toggleSpinner();
    FB.getLoginStatus(
      (e) => {
        if (e.status === 'connected') {
          const curTime = Math.round(new Date().getTime() / 1000);
          const expiresTime = e.authResponse?.data_access_expiration_time || 0;

          if (expiresTime - curTime >= 0) {
            this.socialSuccessCallback(e.authResponse.accessToken, 'facebook');
            return;
          }
        }

        FB.login(
          (el) => {
            if (el.authResponse) {
              this.socialSuccessCallback(
                el.authResponse.accessToken,
                'facebook'
              );
            } else {
              // loginOpenTab();
              const errDiv = this.form.querySelector('.regFormErrMsg');
              errDiv.innerHTML =
                'An error occurred. Please try again or choose another method of registration.';
              errDiv.classList.add('error');
              this.toggleSpinner(false);
            }
          },
          { scope: 'email' }
        );
      },
      { force: 'true' }
    );
  }

  toggleSpinner(enable = true) {
    const spinner = this.form.querySelector('.js-popup-spinner');
    if (spinner) {
      spinner.classList.toggle('hidden', !enable);
    }
  }
}
