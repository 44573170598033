import { validateForm } from '@/helpers/form-validation';
import { processEmailCapture } from '@/core/thinkhuge';

function newsletterSignup(form, source, customAP = false) {
  const email = form.email.value;
  const valid = validateForm(form);

  if (valid) {
    processEmailCapture(email, source, customAP);
    const success = form.nextElementSibling;
    success.classList.remove('hidden');
    form.classList.add('hidden');
    setTimeout(() => {
      success.classList.add('successAnimateOut');
    }, 8000);
  }
}

// I believe this code is now redundant, was from the B version of the wp-sidebar, keeping it here though just incase we need to implement something similar
document
  .querySelectorAll('.js-sidebar-email, .js-email-grabber-signup')
  .forEach((element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      const form = e.target;
      const src = form.querySelector('input[name="source"]')?.value || false;
      const ap =
        form.querySelector('input[name="autopilotJourney"]')?.value || false;
      newsletterSignup(form, src, ap);
    });
  });

// I believe this code is now redundant, was from the B version of the wp-sidebar, keeping it here though just incase we need to implement something similar
document
  .querySelectorAll('.js-email-grabber-generic-form')
  .forEach((element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      const form = e.target;
      const src = form.querySelector('.js-email-grabber-btn').dataset.source;
      const ap =
        form.querySelector('input[name="autopilotJourney"]')?.value || false;
      newsletterSignup(form, src, ap);
    });
  });
