import { detectTouchScreen } from '@/core/utils';
import GLightbox from 'glightbox'; // https://github.com/biati-digital/glightbox#readme

const playerOptions = {
  js: 'https://cdnjs.cloudflare.com/ajax/libs/plyr/3.7.2/plyr.min.js',
  css: 'https://cdnjs.cloudflare.com/ajax/libs/plyr/3.7.2/plyr.min.css',
  config: {
    // storage: {
    //   enabled: false,
    // },
    autoplay: true,
  },
};

const mobileOptions = {
  config: {
    muted: true,
    playsinline: true,
    fullscreen: {
      enabled: true,
      iosNative: true,
    },
    vimeo: {
      muted: true,
      playsinline: true,
    },
  },
};

export const glightbox = GLightbox({
  plyr: detectTouchScreen()
    ? Object.assign(playerOptions, mobileOptions)
    : playerOptions,
});

glightbox.on('open', () => {
  httApp.isAnyPopupActive = true;
});

glightbox.on('close', () => {
  httApp.isAnyPopupActive = false;
});

// prevent merging all boxes to a gallery

document.querySelectorAll('.js-lightbox').forEach((item) => {
  item.addEventListener('click', function (event) {
    event.preventDefault();

    const targetHref = this.getAttribute('href');

    glightbox.setElements([{ href: targetHref }]);
    glightbox.open();
  });
});
