import Cookies from 'js-cookie';

const defaults = {
  useDays: false,
  cookie: false,
  inline: false,
  debug: false,
  hideSeconds: false,
};

const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;

export class Countdown {
  constructor(nodeList, destinationTimestamp, options = {}) {
    this.nodes =
      typeof nodeList === 'string'
        ? document.querySelectorAll(nodeList)
        : nodeList;

    if (!this.nodes.length) {
      return;
    }

    // set defaults

    Object.assign(this, defaults, options);

    if (typeof options.onFinish === 'function') {
      this.onFinish = options.onFinish.bind(this);
    }

    this.isFinished = false;

    // process destination date

    const timestamp = parseInt(destinationTimestamp);

    if (!timestamp) {
      this.finish();
      return;
    }

    this.destinationDate = this.getDestinationDate(timestamp);

    //

    this.init();
  }

  init() {
    this.tick();
    if (this.debug || this.isFinished) return;

    this.intervalId = setInterval(this.tick.bind(this), 1000);
  }

  finish() {
    this.isFinished = true;
    clearInterval(this.intervalId);
    this.onFinishHandler();
  }

  getDestinationDate(timestamp) {
    const date = new Date(timestamp);

    if (!this.cookie) {
      return date;
    }

    const storedTimestamp = Cookies.get(this.cookie);

    if (storedTimestamp) {
      return storedTimestamp;
    }

    Cookies.set(this.cookie, timestamp, { expires: date });
    return date;
  }

  onFinishHandler() {
    if (this.debug) return;

    this.nodes.forEach((node) => node.classList.toggle('hidden', true));

    if (this.onFinish) {
      this.onFinish(this.nodes);
    }
  }

  tick(isFirst = true) {
    const distance = this.destinationDate - new Date().setMilliseconds(0);

    if (distance < 0) {
      this.finish();
      return;
    }

    if (isFirst) {
      this.prepareLayout();
      isFirst = false;
    }

    const days = Math.floor(distance / _day);
    const hours = Math.floor((distance % _day) / _hour);
    const minutes = Math.floor((distance % _hour) / _minute);
    const seconds = Math.floor((distance % _minute) / _second);

    const data = [
      {
        title: 'days',
        value: days,
      },
      {
        title: 'hr',
        value: prependZero(hours),
      },
      {
        title: 'min',
        value: prependZero(minutes),
      },
      {
        title: 'sec',
        value: prependZero(seconds),
      },
    ];

    this.updateLayout(data);
  }

  updateLayout(data) {
    this.nodes.forEach((node) => {
      if (this.inline) {
        if (!this.useDays) data = data.slice(1);
        node.innerHTML = data
          .filter(({ value }) => value !== '00')
          .map(this.toText)
          .join(' ');
      } else {
        Array.from(node.children).forEach(
          (el, index) => (el.dataset.value = data[index].value)
        );
      }
    });
  }

  prepareLayout() {
    this.nodes.forEach((node) => {
      node.classList.toggle('has-days', this.useDays);
      node.classList.toggle('hide-seconds', this.hideSeconds);
      node.classList.toggle('hidden', false);
    });
  }

  toText({ title, value }) {
    return `${value} ${title}`;
  }

  // toHtml({ title, value }) {
  //   const margin = title === 'secs' ? '' : '';
  //   const textAlign = 'center'; // (title === 'days') ? 'right' : 'center';

  //   return `<div class="d-inline-block text-${textAlign}${margin}"><span class="countdown__digit">${value}</span><span class="countdown__date d-block">${title}</span></div>`;
  // }
}

function prependZero(val) {
  return `0${val}`.slice(-2);
}
